import { PageType } from 'types/analytics'

export const DEFAULT_SELLER = 'MANGO'

/** Page types */
export const PAGE_TYPE_BOLSA = PageType.ShoppingCart

/** Stock control */
export enum STOCK {
	/** Some items of the user's shopping cart are out of stock */
	PARTIAL = 'PARTIAL',
	/** All items of the user's shopping cart are out of stock */
	INVALID = 'INVALID',
}

export const CACHE_KEY = 'SHOPPING_CART_CACHE'

export const TOTAL_ITEMS_KEY = 'totalItems'

export const PORTAL_ID = 'shopping-cart-portal'
export const ADD_ITEM_PORTAL_ID = 'add-item-notification-portal'
export const SHOPPING_CART_SERVICES_ENDPOINT = '/ws-graphql-gateway/graphql'

/** Reloads */
export const MAX_RELOAD = 3

/** Checkout info */
export enum SHOPPING_CART_LOCATION {
	PAGE = 'page',
	PREVIEW = 'preview',
	AUTOMATIC = 'automatic_preview',
	CART = 'cart',
}

/** Test ids */
export const LOGIN_MODAL_TEST_ID = 'shoppingCart.loginModal'
export const STOCK_MODAL_TEST_ID = 'shoppingCart.stockModal'
export const ABANDONED_CART_TEST_ID = 'abandonedCart.modal'

export const FLAG_ENABLE_CHECKOUT = 'force_enable_checkout'
export const FEATURE_FLAG_DOMAIN = 'shopping-cart'
export const FEATURE_FLAG_KEY = 'new-checkout'

export const CHECKOUT_CREATE_ACCOUNT_PATHNAME = '/checkout/registry'
export const CHECKOUT_LOGIN_PATHNAME = '/checkout/login'
export const CHECKOUT_DELIVERY_PATHNAME = '/checkout/delivery'
export const CHECKOUT_AUTHENTICATION_PATHNAME = '/checkout/authentication'

export const ADD_NOTIFICATION_TIME = 3000
// +1 second to avoid the notification to be closed before the store is updated
export const ADD_NOTIFICATION_STORE_TIME = ADD_NOTIFICATION_TIME + 1000
export const DEFAULT_LOOK_ID = '00'
