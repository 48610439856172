'use client'

import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { default as fetcherGraphQL } from 'services/graphql/fetcher'
import { useSession } from 'session/src/hooks/useSession'

export const useGraphQL = () => {
	const { token } = useSession()

	const {
		country: { locale, countryISO },
	} = useMasterData()

	async function fetcher<TData, TVariables, TError>({
		url,
		query,
		variables,
		headers,
	}: {
		url: string
		query: string
		variables?: TVariables
		headers?: Record<string, string>
	}): Promise<TData | TError[] | null> {
		return fetcherGraphQL<TData, TVariables, TError>({
			url,
			query,
			variables,
			headers: {
				Authorization: `Bearer ${token}`,
				'Accept-Language': locale,
				'X-Country-State': countryISO,
				...headers,
			},
		})
	}

	return { fetcher }
}
