import { graphql } from 'codegen/src/gateway'

export const addItem = graphql(`
	mutation AddItem(
		$channel: String!
		$item: ShoppingCartAddItemInput!
		$orderBy: ShoppingCartOrderByInput
	) {
		addItem(channel: $channel, item: $item, orderBy: $orderBy) {
			items {
				...FragmentItemsFields
			}
			deliveries {
				order
				items {
					...FragmentItemsFields
				}
			}
			summary {
				...FragmentSummaryDtoFields
			}
			totalItems
			metadata {
				limitsExceeded
			}
		}
	}
`).toString()

export const mergeWithGuest = graphql(`
	mutation MergeWithGuest(
		$channel: String!
		$orderBy: ShoppingCartOrderByInput
	) {
		mergeFrom(channel: $channel, orderBy: $orderBy) {
			merged
			items {
				...FragmentItemsFields
			}
			deliveries {
				order
				items {
					...FragmentItemsFields
				}
			}
			summary {
				...FragmentSummaryDtoFields
			}
			totalItems
			metadata {
				limitsExceeded
			}
		}
	}
`).toString()

export const mergeWithGuestCleanup = graphql(`
	mutation MergeWithGuestCleanup(
		$channel: String!
		$orderBy: ShoppingCartOrderByInput
		$cleanup: Boolean
	) {
		mergeFrom(channel: $channel, orderBy: $orderBy, cleanup: $cleanup) {
			merged
		}
	}
`).toString()

export const removeItem = graphql(`
	mutation RemoveItem(
		$channel: String!
		$itemId: String!
		$orderBy: ShoppingCartOrderByInput
	) {
		removeItem(channel: $channel, itemId: $itemId, orderBy: $orderBy) {
			items {
				...FragmentItemsFields
			}
			deliveries {
				order
				items {
					...FragmentItemsFields
				}
			}
			summary {
				...FragmentSummaryDtoFields
			}
			totalItems
			metadata {
				limitsExceeded
			}
		}
	}
`).toString()

export const clearItem = graphql(`
	mutation ClearItem(
		$channel: String!
		$itemId: String!
		$orderBy: ShoppingCartOrderByInput
	) {
		clearItem(channel: $channel, itemId: $itemId, orderBy: $orderBy) {
			items {
				...FragmentItemsFields
			}
			deliveries {
				order
				items {
					...FragmentItemsFields
				}
			}
			summary {
				...FragmentSummaryDtoFields
			}
			totalItems
			metadata {
				limitsExceeded
			}
		}
	}
`).toString()
