export function ButtonBase(
	props: React.ComponentProps<'button'>
): React.ReactNode {
	const { children, type, onClick, disabled, ...rest } = props

	const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		if (disabled) {
			event.preventDefault()
			event.stopPropagation()
		} else {
			onClick?.(event)
		}
	}

	return (
		<button
			{...rest}
			type={type ?? 'button'}
			onClick={handleOnClick}
			aria-disabled={disabled}
		>
			{children}
		</button>
	)
}
