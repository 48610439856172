interface GraphQLWithData<TData> {
	data: TData
	errors?: never
}
interface GraphQLWithErrors<TError> {
	data: null
	errors: TError[]
}
export type GraphQLType<TData, TError = void> =
	| GraphQLWithData<TData>
	| GraphQLWithErrors<TError>

async function fetcher<TData, TVariables, TError = void>({
	url,
	query,
	variables,
	headers,
}: {
	url: string
	query: string
	variables?: TVariables
	headers?: Record<string, string>
}): Promise<TData | TError[] | null> {
	try {
		const response: Response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				...headers,
			},
			body: JSON.stringify({
				query,
				variables,
			}),
			next: {
				revalidate: 0,
			},
		})

		if (response.ok) {
			const json = (await response.json()) as GraphQLType<TData, TError>
			if (json.errors) {
				return json.errors
			}
			return json.data ? json.data : null
		}

		let errorMessage = ''

		try {
			const jsonError = await response.json()
			errorMessage = JSON.stringify(jsonError)
		} catch (e) {
			errorMessage = response.statusText
		}

		// eslint-disable-next-line no-console
		console.error(
			`Error fetching data on ${url}`,
			response.status,
			errorMessage
		)
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error(e)
	}

	return null
}

export default fetcher
