import { LayoutSetStateLabelsContext } from 'labels/context/LayoutLabelsProvider/LayoutLabelsProvider'
import { useContext } from 'react'

/** @throws {Error} If useLabels is used without LayoutLabelsProvider */
export const useSetLabels = () => {
	const setLabels = useContext(LayoutSetStateLabelsContext)
	if (!setLabels) {
		throw new Error('useSetLabels must be used withing a LayoutLabelsProvider')
	}
	return setLabels
}
