/* eslint-disable */
import * as types from './graphql'

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
	'fragment FragmentItemsFields on ItemDto {\n  colorId\n  customized\n  itemId\n  look\n  price {\n    current {\n      currentPrice: amount\n    }\n    initial {\n      initialPrice: amount\n    }\n    discountRate\n    promotionName\n    previous {\n      lowest {\n        promo {\n          amount\n        }\n        sale {\n          amount\n        }\n      }\n      original {\n        shop {\n          amount\n        }\n        outlet {\n          amount\n        }\n      }\n    }\n    star\n    type\n  }\n  productId\n  hasLowStock\n  quantity\n  seller\n  sizeId\n  outOfStock\n  warehouses\n}\n\nfragment FragmentSummaryDtoFields on SummaryDto {\n  deliveryCost {\n    deliveryAmount: amount\n  }\n  freeShipping\n  promoteShippingToStore\n  subtotal {\n    subTotalPrice: amount\n  }\n  total {\n    totalPrice: amount\n  }\n  remainingCostToBeFreeShipping {\n    untilFree: amount\n  }\n  taxable\n  saved {\n    amount\n  }\n  original {\n    amount\n  }\n}':
		types.FragmentItemsFieldsFragmentDoc,
	'\n\tmutation AddItem(\n\t\t$channel: String!\n\t\t$item: ShoppingCartAddItemInput!\n\t\t$orderBy: ShoppingCartOrderByInput\n\t) {\n\t\taddItem(channel: $channel, item: $item, orderBy: $orderBy) {\n\t\t\titems {\n\t\t\t\t...FragmentItemsFields\n\t\t\t}\n\t\t\tdeliveries {\n\t\t\t\torder\n\t\t\t\titems {\n\t\t\t\t\t...FragmentItemsFields\n\t\t\t\t}\n\t\t\t}\n\t\t\tsummary {\n\t\t\t\t...FragmentSummaryDtoFields\n\t\t\t}\n\t\t\ttotalItems\n\t\t\tmetadata {\n\t\t\t\tlimitsExceeded\n\t\t\t}\n\t\t}\n\t}\n':
		types.AddItemDocument,
	'\n\tmutation MergeWithGuest(\n\t\t$channel: String!\n\t\t$orderBy: ShoppingCartOrderByInput\n\t) {\n\t\tmergeFrom(channel: $channel, orderBy: $orderBy) {\n\t\t\tmerged\n\t\t\titems {\n\t\t\t\t...FragmentItemsFields\n\t\t\t}\n\t\t\tdeliveries {\n\t\t\t\torder\n\t\t\t\titems {\n\t\t\t\t\t...FragmentItemsFields\n\t\t\t\t}\n\t\t\t}\n\t\t\tsummary {\n\t\t\t\t...FragmentSummaryDtoFields\n\t\t\t}\n\t\t\ttotalItems\n\t\t\tmetadata {\n\t\t\t\tlimitsExceeded\n\t\t\t}\n\t\t}\n\t}\n':
		types.MergeWithGuestDocument,
	'\n\tmutation MergeWithGuestCleanup(\n\t\t$channel: String!\n\t\t$orderBy: ShoppingCartOrderByInput\n\t\t$cleanup: Boolean\n\t) {\n\t\tmergeFrom(channel: $channel, orderBy: $orderBy, cleanup: $cleanup) {\n\t\t\tmerged\n\t\t}\n\t}\n':
		types.MergeWithGuestCleanupDocument,
	'\n\tmutation RemoveItem(\n\t\t$channel: String!\n\t\t$itemId: String!\n\t\t$orderBy: ShoppingCartOrderByInput\n\t) {\n\t\tremoveItem(channel: $channel, itemId: $itemId, orderBy: $orderBy) {\n\t\t\titems {\n\t\t\t\t...FragmentItemsFields\n\t\t\t}\n\t\t\tdeliveries {\n\t\t\t\torder\n\t\t\t\titems {\n\t\t\t\t\t...FragmentItemsFields\n\t\t\t\t}\n\t\t\t}\n\t\t\tsummary {\n\t\t\t\t...FragmentSummaryDtoFields\n\t\t\t}\n\t\t\ttotalItems\n\t\t\tmetadata {\n\t\t\t\tlimitsExceeded\n\t\t\t}\n\t\t}\n\t}\n':
		types.RemoveItemDocument,
	'\n\tmutation ClearItem(\n\t\t$channel: String!\n\t\t$itemId: String!\n\t\t$orderBy: ShoppingCartOrderByInput\n\t) {\n\t\tclearItem(channel: $channel, itemId: $itemId, orderBy: $orderBy) {\n\t\t\titems {\n\t\t\t\t...FragmentItemsFields\n\t\t\t}\n\t\t\tdeliveries {\n\t\t\t\torder\n\t\t\t\titems {\n\t\t\t\t\t...FragmentItemsFields\n\t\t\t\t}\n\t\t\t}\n\t\t\tsummary {\n\t\t\t\t...FragmentSummaryDtoFields\n\t\t\t}\n\t\t\ttotalItems\n\t\t\tmetadata {\n\t\t\t\tlimitsExceeded\n\t\t\t}\n\t\t}\n\t}\n':
		types.ClearItemDocument,
	'\n\tquery FindByUser($channel: String!, $orderBy: ShoppingCartOrderByInput) {\n\t\tfindByUser(channel: $channel, orderBy: $orderBy) {\n\t\t\t... on ShoppingCartDto {\n\t\t\t\ttotalItems\n\t\t\t\tdeliveries {\n\t\t\t\t\torder\n\t\t\t\t\titems {\n\t\t\t\t\t\t...FragmentItemsFields\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\titems {\n\t\t\t\t\t...FragmentItemsFields\n\t\t\t\t}\n\t\t\t\tsummary {\n\t\t\t\t\t...FragmentSummaryDtoFields\n\t\t\t\t}\n\t\t\t\tmetadata {\n\t\t\t\t\tlimitsExceeded\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n':
		types.FindByUserDocument,
	'\n\tquery FindTotalItemsByUser($channel: String!) {\n\t\tfindTotalItemsByUser(channel: $channel) {\n\t\t\ttotalItems\n\t\t}\n\t}\n':
		types.FindTotalItemsByUserDocument,
	'\n\tfragment FragmentPromotionsFields on DraftPurchasePromotions {\n\t\tcodes {\n\t\t\ttype\n\t\t\tname\n\t\t}\n\t\tdiscount {\n\t\t\tamount\n\t\t\tcurrency\n\t\t\tdecimals\n\t\t}\n\t\tfreeShipping\n\t\tsomeItemsOnly\n\t}\n':
		types.FragmentPromotionsFieldsFragmentDoc,
	'\n\tfragment FragmentDeliveryMethods on DeliveryMethod {\n\t\tcategory\n\t\testimatedDelivery {\n\t\t\tdateFrom\n\t\t\tdateTo\n\t\t}\n\t\tid\n\t\tprice {\n\t\t\tamount\n\t\t\tcurrency\n\t\t\tdecimals\n\t\t}\n\t\tisPremium\n\t}\n':
		types.FragmentDeliveryMethodsFragmentDoc,
	'\n\tfragment FragmentGiftVoucherFields on GiftVoucher {\n\t\tcode\n\t\tbalance {\n\t\t\tamount\n\t\t\tcurrency\n\t\t\tdecimals\n\t\t}\n\t\tapplied {\n\t\t\tamount\n\t\t\tcurrency\n\t\t\tdecimals\n\t\t}\n\t}\n':
		types.FragmentGiftVoucherFieldsFragmentDoc,
	'\n\tfragment FragmentSelectedDeliveryMethod on SelectedDeliveryMethod {\n\t\t... on SelectedHomeDeliveryMethod {\n\t\t\tdeliveryMethodId\n\t\t\tdeliveryAddressId\n\t\t}\n\t\t... on SelectedStoreDeliveryMethod {\n\t\t\tdeliveryMethodId\n\t\t\tstoreId\n\t\t\tpickingData {\n\t\t\t\taddress\n\t\t\t\tcity\n\t\t\t\tcountryCode\n\t\t\t\tname\n\t\t\t\tsurname\n\t\t\t\temail\n\t\t\t\tphone\n\t\t\t\tphonePrefix\n\t\t\t\tpostalCode\n\t\t\t\tprovinceId\n\t\t\t}\n\t\t}\n\t\t... on SelectedMangoDeliveryMethod {\n\t\t\tdeliveryMethodId\n\t\t\tpickingData {\n\t\t\t\taddress\n\t\t\t\tcity\n\t\t\t\tcountryCode\n\t\t\t\tname\n\t\t\t\tsurname\n\t\t\t\temail\n\t\t\t\tphone\n\t\t\t\tphonePrefix\n\t\t\t\tpostalCode\n\t\t\t\tprovinceId\n\t\t\t}\n\t\t}\n\t\t... on SelectedDropPointDeliveryMethod {\n\t\t\tdeliveryMethodId\n\t\t\tdropPointId\n\t\t\tpickingData {\n\t\t\t\taddress\n\t\t\t\tcity\n\t\t\t\tcountryCode\n\t\t\t\tname\n\t\t\t\tsurname\n\t\t\t\temail\n\t\t\t\tphone\n\t\t\t\tphonePrefix\n\t\t\t\tpostalCode\n\t\t\t\tprovinceId\n\t\t\t}\n\t\t}\n\t}\n':
		types.FragmentSelectedDeliveryMethodFragmentDoc,
	'\n\tfragment FragmentSummaryFields on Summary {\n\t\tloyaltyDiscount {\n\t\t\tamount\n\t\t\tcurrency\n\t\t\tdecimals\n\t\t}\n\t\tpromoDiscount {\n\t\t\tamount\n\t\t\tcurrency\n\t\t\tdecimals\n\t\t}\n\t\tgiftVoucherAmount {\n\t\t\tamount\n\t\t\tcurrency\n\t\t\tdecimals\n\t\t}\n\t\tshipping {\n\t\t\tamount\n\t\t\tcurrency\n\t\t\tdecimals\n\t\t}\n\t\tsubtotal {\n\t\t\tamount\n\t\t\tcurrency\n\t\t\tdecimals\n\t\t}\n\t\tsubtotalWithShipping {\n\t\t\tamount\n\t\t\tcurrency\n\t\t\tdecimals\n\t\t}\n\t\ttotal {\n\t\t\tamount\n\t\t\tcurrency\n\t\t\tdecimals\n\t\t}\n\t\ttotalItems\n\t}\n':
		types.FragmentSummaryFieldsFragmentDoc,
	'\n\tfragment FragmentDeliveryAddressFields on DeliveryAddress {\n\t\taddress1\n\t\taddress2\n\t\taddress3\n\t\tareaCode\n\t\tcity\n\t\tcountry\n\t\temail\n\t\tfirstName\n\t\tfullAddress\n\t\tid\n\t\tlastName\n\t\tphoneNumber\n\t\tstate\n\t\ttin\n\t\tzipCode\n\t\tisValidAddress\n\t}\n':
		types.FragmentDeliveryAddressFieldsFragmentDoc,
	'\n\tfragment FragmentBillingAddressFields on BillingAddress {\n\t\taddress1\n\t\taddress2\n\t\taddress3\n\t\tareaCode\n\t\tcity\n\t\tcountry\n\t\temail\n\t\tfirstName\n\t\tfullAddress\n\t\tid\n\t\tlastName\n\t\tphoneNumber\n\t\tstate\n\t\ttin\n\t\tzipCode\n\t\tisValidAddress\n\t}\n':
		types.FragmentBillingAddressFieldsFragmentDoc,
	'\n\tfragment FragmentItemFields on ItemCheckoutDto {\n\t\tchannel\n\t\tcolorId\n\t\tcustomized\n\t\tcustomizedData {\n\t\t\tcollectionId\n\t\t\tcustomColor\n\t\t\tcustomPosition\n\t\t\tcustomSize\n\t\t\tcustomType\n\t\t\tneedleWork\n\t\t}\n\t\titemId\n\t\tlook\n\t\toutOfStock\n\t\tprice {\n\t\t\tcurrent {\n\t\t\t\tamount\n\t\t\t\tcurrency\n\t\t\t\tdecimals\n\t\t\t}\n\t\t\tinitial {\n\t\t\t\tamount\n\t\t\t\tcurrency\n\t\t\t\tdecimals\n\t\t\t}\n\t\t\topi\n\t\t\toriginal {\n\t\t\t\tamount\n\t\t\t\tcurrency\n\t\t\t\tdecimals\n\t\t\t}\n\t\t\tpromotionName\n\t\t\tstar\n\t\t\ttype\n\t\t}\n\t\tproductId\n\t\tproductInfo {\n\t\t\tcolorName\n\t\t\timage\n\t\t\tname\n\t\t\tsizeName\n\t\t}\n\t\tpromotion {\n\t\t\tcode\n\t\t\tprice {\n\t\t\t\tamount\n\t\t\t\tcurrency\n\t\t\t\tdecimals\n\t\t\t}\n\t\t}\n\t\tquantity\n\t\tseller\n\t\tsizeId\n\t\tsku\n\t}\n':
		types.FragmentItemFieldsFragmentDoc,
	'\n\t\n\t\n\tfragment FragmentDeliveriesFields on DraftPurchaseDelivery {\n\t\tdeliveryAddress {\n\t\t\t...FragmentDeliveryAddressFields\n\t\t}\n\t\tid\n\t\titems {\n\t\t\t...FragmentItemFields\n\t\t}\n\t\torder\n\t\tseller\n\t\twarehouse\n\t}\n':
		types.FragmentDeliveriesFieldsFragmentDoc,
	'\n\tfragment FragmentCountryFeatures on CountryFeatures {\n\t\thasPremiumDeliveryMethods\n\t}\n':
		types.FragmentCountryFeaturesFragmentDoc,
	'\n\t\n\t\n\t\n\t\n\t\n\t\n\t\n\t\n\tfragment FragmentDraftPurchaseFields on DraftPurchase {\n\t\tid\n\t\tuserId\n\t\tcurrency\n\t\tbillingAddressId\n\t\tbillingAddress {\n\t\t\t...FragmentBillingAddressFields\n\t\t}\n\t\tdeliveries {\n\t\t\t...FragmentDeliveriesFields\n\t\t}\n\t\tdeliveryMethods {\n\t\t\t...FragmentDeliveryMethods\n\t\t}\n\t\tgiftVoucher {\n\t\t\t...FragmentGiftVoucherFields\n\t\t}\n\t\tselectedDeliveryMethod {\n\t\t\t...FragmentSelectedDeliveryMethod\n\t\t}\n\t\tpromotions {\n\t\t\t...FragmentPromotionsFields\n\t\t}\n\t\tsummary {\n\t\t\t...FragmentSummaryFields\n\t\t}\n\t\tcommunicationMessages {\n\t\t\tid\n\t\t}\n\t\tcountryFeatures {\n\t\t\t...FragmentCountryFeatures\n\t\t}\n\t}\n':
		types.FragmentDraftPurchaseFieldsFragmentDoc,
	'\n\t\t\n\t\tmutation UpdateDeliveryAddress(\n\t\t\t$updateDeliveryAddressInput: UpdateDeliveryAddressInput!\n\t\t) {\n\t\t\tupdateDeliveryAddress(\n\t\t\t\tupdateDeliveryAddressInput: $updateDeliveryAddressInput\n\t\t\t) {\n\t\t\t\t...FragmentDraftPurchaseFields\n\t\t\t}\n\t\t}\n\t':
		types.UpdateDeliveryAddressDocument,
	'\n\t\t\n\t\tmutation CreateDeliveryAddress(\n\t\t\t$createDeliveryAddressInput: CreateDeliveryAddressInput!\n\t\t) {\n\t\t\tcreateDeliveryAddress(\n\t\t\t\tcreateDeliveryAddressInput: $createDeliveryAddressInput\n\t\t\t) {\n\t\t\t\t...FragmentDraftPurchaseFields\n\t\t\t}\n\t\t}\n\t':
		types.CreateDeliveryAddressDocument,
	'\n\t\t\n\t\tmutation RemoveCheckoutItem($removeItemInput: RemoveItemInput!) {\n\t\t\tremoveCheckoutItem(removeItemInput: $removeItemInput) {\n\t\t\t\t...FragmentDraftPurchaseFields\n\t\t\t}\n\t\t}\n\t':
		types.RemoveCheckoutItemDocument,
	'\n\t\t\n\t\tmutation ApplyPromotionalCode(\n\t\t\t$promotionalCodeInput: PromotionalCodeInput!\n\t\t) {\n\t\t\tapplyPromotionalCode(promotionalCodeInput: $promotionalCodeInput) {\n\t\t\t\t...FragmentDraftPurchaseFields\n\t\t\t}\n\t\t}\n\t':
		types.ApplyPromotionalCodeDocument,
	'\n\t\t\n\t\tmutation CancelPromotionalCode(\n\t\t\t$promotionalCodeInput: PromotionalCodeInput!\n\t\t) {\n\t\t\tcancelPromotionalCode(promotionalCodeInput: $promotionalCodeInput) {\n\t\t\t\t...FragmentDraftPurchaseFields\n\t\t\t}\n\t\t}\n\t':
		types.CancelPromotionalCodeDocument,
	'\n\t\t\n\t\tmutation ApplyGiftVoucher($giftVoucherInput: GiftVoucherInput!) {\n\t\t\tapplyGiftVoucher(giftVoucherInput: $giftVoucherInput) {\n\t\t\t\t...FragmentDraftPurchaseFields\n\t\t\t}\n\t\t}\n\t':
		types.ApplyGiftVoucherDocument,
	'\n\t\t\n\t\tmutation CancelGiftVoucher($giftVoucherInput: GiftVoucherInput!) {\n\t\t\tcancelGiftVoucher(giftVoucherInput: $giftVoucherInput) {\n\t\t\t\t...FragmentDraftPurchaseFields\n\t\t\t}\n\t\t}\n\t':
		types.CancelGiftVoucherDocument,
	'\n\t\t\n\t\tmutation MergeDraftPurchase(\n\t\t\t$mergeDraftPurchaseInput: MergeDraftPurchaseInput!\n\t\t) {\n\t\t\tmergeDraftPurchase(mergeDraftPurchaseInput: $mergeDraftPurchaseInput) {\n\t\t\t\t...FragmentDraftPurchaseFields\n\t\t\t}\n\t\t}\n\t':
		types.MergeDraftPurchaseDocument,
	'\n\t\t\n\t\tmutation SelectDeliveryMethod(\n\t\t\t$selectDeliveryMethodInput: SelectDeliveryMethodInput!\n\t\t) {\n\t\t\tselectDeliveryMethod(\n\t\t\t\tselectDeliveryMethodInput: $selectDeliveryMethodInput\n\t\t\t) {\n\t\t\t\t...FragmentDraftPurchaseFields\n\t\t\t}\n\t\t}\n\t':
		types.SelectDeliveryMethodDocument,
	'\n\t\t\n\t\tmutation SelectedDraftPurchaseDeliveryMethod(\n\t\t\t$selectedDeliveryMethodInput: SelectedDeliveryMethodInput!\n\t\t) {\n\t\t\tselectedDraftPurchaseDeliveryMethod(\n\t\t\t\tselectedDeliveryMethodInput: $selectedDeliveryMethodInput\n\t\t\t) {\n\t\t\t\t...FragmentDraftPurchaseFields\n\t\t\t}\n\t\t}\n\t':
		types.SelectedDraftPurchaseDeliveryMethodDocument,
	'\n\t\t\n\t\tmutation RefreshDeliveryMethods(\n\t\t\t$refreshDeliveryMethodsInput: RefreshDeliveryMethodsInput!\n\t\t) {\n\t\t\trefreshDeliveryMethods(\n\t\t\t\trefreshDeliveryMethodsInput: $refreshDeliveryMethodsInput\n\t\t\t) {\n\t\t\t\t...FragmentDraftPurchaseFields\n\t\t\t}\n\t\t}\n\t':
		types.RefreshDeliveryMethodsDocument,
	'\n\t\tmutation InitializeCheckout(\n\t\t\t$initializeCheckoutInput: InitializeCheckoutInput!\n\t\t) {\n\t\t\tinitializeCheckout(initializeCheckoutInput: $initializeCheckoutInput) {\n\t\t\t\t__typename\n\t\t\t}\n\t\t}\n\t':
		types.InitializeCheckoutDocument,
	'\n\t\t\n\t\tquery GetDraftPurchase($getDraftPurchaseInput: GetDraftPurchaseInput!) {\n\t\t\tgetDraftPurchase(getDraftPurchaseInput: $getDraftPurchaseInput) {\n\t\t\t\t...FragmentDraftPurchaseFields\n\t\t\t}\n\t\t}\n\t':
		types.GetDraftPurchaseDocument,
}

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: 'fragment FragmentItemsFields on ItemDto {\n  colorId\n  customized\n  itemId\n  look\n  price {\n    current {\n      currentPrice: amount\n    }\n    initial {\n      initialPrice: amount\n    }\n    discountRate\n    promotionName\n    previous {\n      lowest {\n        promo {\n          amount\n        }\n        sale {\n          amount\n        }\n      }\n      original {\n        shop {\n          amount\n        }\n        outlet {\n          amount\n        }\n      }\n    }\n    star\n    type\n  }\n  productId\n  hasLowStock\n  quantity\n  seller\n  sizeId\n  outOfStock\n  warehouses\n}\n\nfragment FragmentSummaryDtoFields on SummaryDto {\n  deliveryCost {\n    deliveryAmount: amount\n  }\n  freeShipping\n  promoteShippingToStore\n  subtotal {\n    subTotalPrice: amount\n  }\n  total {\n    totalPrice: amount\n  }\n  remainingCostToBeFreeShipping {\n    untilFree: amount\n  }\n  taxable\n  saved {\n    amount\n  }\n  original {\n    amount\n  }\n}'
): typeof import('./graphql').FragmentItemsFieldsFragmentDoc
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation AddItem(\n\t\t$channel: String!\n\t\t$item: ShoppingCartAddItemInput!\n\t\t$orderBy: ShoppingCartOrderByInput\n\t) {\n\t\taddItem(channel: $channel, item: $item, orderBy: $orderBy) {\n\t\t\titems {\n\t\t\t\t...FragmentItemsFields\n\t\t\t}\n\t\t\tdeliveries {\n\t\t\t\torder\n\t\t\t\titems {\n\t\t\t\t\t...FragmentItemsFields\n\t\t\t\t}\n\t\t\t}\n\t\t\tsummary {\n\t\t\t\t...FragmentSummaryDtoFields\n\t\t\t}\n\t\t\ttotalItems\n\t\t\tmetadata {\n\t\t\t\tlimitsExceeded\n\t\t\t}\n\t\t}\n\t}\n'
): typeof import('./graphql').AddItemDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation MergeWithGuest(\n\t\t$channel: String!\n\t\t$orderBy: ShoppingCartOrderByInput\n\t) {\n\t\tmergeFrom(channel: $channel, orderBy: $orderBy) {\n\t\t\tmerged\n\t\t\titems {\n\t\t\t\t...FragmentItemsFields\n\t\t\t}\n\t\t\tdeliveries {\n\t\t\t\torder\n\t\t\t\titems {\n\t\t\t\t\t...FragmentItemsFields\n\t\t\t\t}\n\t\t\t}\n\t\t\tsummary {\n\t\t\t\t...FragmentSummaryDtoFields\n\t\t\t}\n\t\t\ttotalItems\n\t\t\tmetadata {\n\t\t\t\tlimitsExceeded\n\t\t\t}\n\t\t}\n\t}\n'
): typeof import('./graphql').MergeWithGuestDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation MergeWithGuestCleanup(\n\t\t$channel: String!\n\t\t$orderBy: ShoppingCartOrderByInput\n\t\t$cleanup: Boolean\n\t) {\n\t\tmergeFrom(channel: $channel, orderBy: $orderBy, cleanup: $cleanup) {\n\t\t\tmerged\n\t\t}\n\t}\n'
): typeof import('./graphql').MergeWithGuestCleanupDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation RemoveItem(\n\t\t$channel: String!\n\t\t$itemId: String!\n\t\t$orderBy: ShoppingCartOrderByInput\n\t) {\n\t\tremoveItem(channel: $channel, itemId: $itemId, orderBy: $orderBy) {\n\t\t\titems {\n\t\t\t\t...FragmentItemsFields\n\t\t\t}\n\t\t\tdeliveries {\n\t\t\t\torder\n\t\t\t\titems {\n\t\t\t\t\t...FragmentItemsFields\n\t\t\t\t}\n\t\t\t}\n\t\t\tsummary {\n\t\t\t\t...FragmentSummaryDtoFields\n\t\t\t}\n\t\t\ttotalItems\n\t\t\tmetadata {\n\t\t\t\tlimitsExceeded\n\t\t\t}\n\t\t}\n\t}\n'
): typeof import('./graphql').RemoveItemDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tmutation ClearItem(\n\t\t$channel: String!\n\t\t$itemId: String!\n\t\t$orderBy: ShoppingCartOrderByInput\n\t) {\n\t\tclearItem(channel: $channel, itemId: $itemId, orderBy: $orderBy) {\n\t\t\titems {\n\t\t\t\t...FragmentItemsFields\n\t\t\t}\n\t\t\tdeliveries {\n\t\t\t\torder\n\t\t\t\titems {\n\t\t\t\t\t...FragmentItemsFields\n\t\t\t\t}\n\t\t\t}\n\t\t\tsummary {\n\t\t\t\t...FragmentSummaryDtoFields\n\t\t\t}\n\t\t\ttotalItems\n\t\t\tmetadata {\n\t\t\t\tlimitsExceeded\n\t\t\t}\n\t\t}\n\t}\n'
): typeof import('./graphql').ClearItemDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery FindByUser($channel: String!, $orderBy: ShoppingCartOrderByInput) {\n\t\tfindByUser(channel: $channel, orderBy: $orderBy) {\n\t\t\t... on ShoppingCartDto {\n\t\t\t\ttotalItems\n\t\t\t\tdeliveries {\n\t\t\t\t\torder\n\t\t\t\t\titems {\n\t\t\t\t\t\t...FragmentItemsFields\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\titems {\n\t\t\t\t\t...FragmentItemsFields\n\t\t\t\t}\n\t\t\t\tsummary {\n\t\t\t\t\t...FragmentSummaryDtoFields\n\t\t\t\t}\n\t\t\t\tmetadata {\n\t\t\t\t\tlimitsExceeded\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n'
): typeof import('./graphql').FindByUserDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery FindTotalItemsByUser($channel: String!) {\n\t\tfindTotalItemsByUser(channel: $channel) {\n\t\t\ttotalItems\n\t\t}\n\t}\n'
): typeof import('./graphql').FindTotalItemsByUserDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tfragment FragmentPromotionsFields on DraftPurchasePromotions {\n\t\tcodes {\n\t\t\ttype\n\t\t\tname\n\t\t}\n\t\tdiscount {\n\t\t\tamount\n\t\t\tcurrency\n\t\t\tdecimals\n\t\t}\n\t\tfreeShipping\n\t\tsomeItemsOnly\n\t}\n'
): typeof import('./graphql').FragmentPromotionsFieldsFragmentDoc
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tfragment FragmentDeliveryMethods on DeliveryMethod {\n\t\tcategory\n\t\testimatedDelivery {\n\t\t\tdateFrom\n\t\t\tdateTo\n\t\t}\n\t\tid\n\t\tprice {\n\t\t\tamount\n\t\t\tcurrency\n\t\t\tdecimals\n\t\t}\n\t\tisPremium\n\t}\n'
): typeof import('./graphql').FragmentDeliveryMethodsFragmentDoc
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tfragment FragmentGiftVoucherFields on GiftVoucher {\n\t\tcode\n\t\tbalance {\n\t\t\tamount\n\t\t\tcurrency\n\t\t\tdecimals\n\t\t}\n\t\tapplied {\n\t\t\tamount\n\t\t\tcurrency\n\t\t\tdecimals\n\t\t}\n\t}\n'
): typeof import('./graphql').FragmentGiftVoucherFieldsFragmentDoc
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tfragment FragmentSelectedDeliveryMethod on SelectedDeliveryMethod {\n\t\t... on SelectedHomeDeliveryMethod {\n\t\t\tdeliveryMethodId\n\t\t\tdeliveryAddressId\n\t\t}\n\t\t... on SelectedStoreDeliveryMethod {\n\t\t\tdeliveryMethodId\n\t\t\tstoreId\n\t\t\tpickingData {\n\t\t\t\taddress\n\t\t\t\tcity\n\t\t\t\tcountryCode\n\t\t\t\tname\n\t\t\t\tsurname\n\t\t\t\temail\n\t\t\t\tphone\n\t\t\t\tphonePrefix\n\t\t\t\tpostalCode\n\t\t\t\tprovinceId\n\t\t\t}\n\t\t}\n\t\t... on SelectedMangoDeliveryMethod {\n\t\t\tdeliveryMethodId\n\t\t\tpickingData {\n\t\t\t\taddress\n\t\t\t\tcity\n\t\t\t\tcountryCode\n\t\t\t\tname\n\t\t\t\tsurname\n\t\t\t\temail\n\t\t\t\tphone\n\t\t\t\tphonePrefix\n\t\t\t\tpostalCode\n\t\t\t\tprovinceId\n\t\t\t}\n\t\t}\n\t\t... on SelectedDropPointDeliveryMethod {\n\t\t\tdeliveryMethodId\n\t\t\tdropPointId\n\t\t\tpickingData {\n\t\t\t\taddress\n\t\t\t\tcity\n\t\t\t\tcountryCode\n\t\t\t\tname\n\t\t\t\tsurname\n\t\t\t\temail\n\t\t\t\tphone\n\t\t\t\tphonePrefix\n\t\t\t\tpostalCode\n\t\t\t\tprovinceId\n\t\t\t}\n\t\t}\n\t}\n'
): typeof import('./graphql').FragmentSelectedDeliveryMethodFragmentDoc
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tfragment FragmentSummaryFields on Summary {\n\t\tloyaltyDiscount {\n\t\t\tamount\n\t\t\tcurrency\n\t\t\tdecimals\n\t\t}\n\t\tpromoDiscount {\n\t\t\tamount\n\t\t\tcurrency\n\t\t\tdecimals\n\t\t}\n\t\tgiftVoucherAmount {\n\t\t\tamount\n\t\t\tcurrency\n\t\t\tdecimals\n\t\t}\n\t\tshipping {\n\t\t\tamount\n\t\t\tcurrency\n\t\t\tdecimals\n\t\t}\n\t\tsubtotal {\n\t\t\tamount\n\t\t\tcurrency\n\t\t\tdecimals\n\t\t}\n\t\tsubtotalWithShipping {\n\t\t\tamount\n\t\t\tcurrency\n\t\t\tdecimals\n\t\t}\n\t\ttotal {\n\t\t\tamount\n\t\t\tcurrency\n\t\t\tdecimals\n\t\t}\n\t\ttotalItems\n\t}\n'
): typeof import('./graphql').FragmentSummaryFieldsFragmentDoc
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tfragment FragmentDeliveryAddressFields on DeliveryAddress {\n\t\taddress1\n\t\taddress2\n\t\taddress3\n\t\tareaCode\n\t\tcity\n\t\tcountry\n\t\temail\n\t\tfirstName\n\t\tfullAddress\n\t\tid\n\t\tlastName\n\t\tphoneNumber\n\t\tstate\n\t\ttin\n\t\tzipCode\n\t\tisValidAddress\n\t}\n'
): typeof import('./graphql').FragmentDeliveryAddressFieldsFragmentDoc
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tfragment FragmentBillingAddressFields on BillingAddress {\n\t\taddress1\n\t\taddress2\n\t\taddress3\n\t\tareaCode\n\t\tcity\n\t\tcountry\n\t\temail\n\t\tfirstName\n\t\tfullAddress\n\t\tid\n\t\tlastName\n\t\tphoneNumber\n\t\tstate\n\t\ttin\n\t\tzipCode\n\t\tisValidAddress\n\t}\n'
): typeof import('./graphql').FragmentBillingAddressFieldsFragmentDoc
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tfragment FragmentItemFields on ItemCheckoutDto {\n\t\tchannel\n\t\tcolorId\n\t\tcustomized\n\t\tcustomizedData {\n\t\t\tcollectionId\n\t\t\tcustomColor\n\t\t\tcustomPosition\n\t\t\tcustomSize\n\t\t\tcustomType\n\t\t\tneedleWork\n\t\t}\n\t\titemId\n\t\tlook\n\t\toutOfStock\n\t\tprice {\n\t\t\tcurrent {\n\t\t\t\tamount\n\t\t\t\tcurrency\n\t\t\t\tdecimals\n\t\t\t}\n\t\t\tinitial {\n\t\t\t\tamount\n\t\t\t\tcurrency\n\t\t\t\tdecimals\n\t\t\t}\n\t\t\topi\n\t\t\toriginal {\n\t\t\t\tamount\n\t\t\t\tcurrency\n\t\t\t\tdecimals\n\t\t\t}\n\t\t\tpromotionName\n\t\t\tstar\n\t\t\ttype\n\t\t}\n\t\tproductId\n\t\tproductInfo {\n\t\t\tcolorName\n\t\t\timage\n\t\t\tname\n\t\t\tsizeName\n\t\t}\n\t\tpromotion {\n\t\t\tcode\n\t\t\tprice {\n\t\t\t\tamount\n\t\t\t\tcurrency\n\t\t\t\tdecimals\n\t\t\t}\n\t\t}\n\t\tquantity\n\t\tseller\n\t\tsizeId\n\t\tsku\n\t}\n'
): typeof import('./graphql').FragmentItemFieldsFragmentDoc
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\t\n\t\n\tfragment FragmentDeliveriesFields on DraftPurchaseDelivery {\n\t\tdeliveryAddress {\n\t\t\t...FragmentDeliveryAddressFields\n\t\t}\n\t\tid\n\t\titems {\n\t\t\t...FragmentItemFields\n\t\t}\n\t\torder\n\t\tseller\n\t\twarehouse\n\t}\n'
): typeof import('./graphql').FragmentDeliveriesFieldsFragmentDoc
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tfragment FragmentCountryFeatures on CountryFeatures {\n\t\thasPremiumDeliveryMethods\n\t}\n'
): typeof import('./graphql').FragmentCountryFeaturesFragmentDoc
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\t\n\t\n\t\n\t\n\t\n\t\n\t\n\t\n\tfragment FragmentDraftPurchaseFields on DraftPurchase {\n\t\tid\n\t\tuserId\n\t\tcurrency\n\t\tbillingAddressId\n\t\tbillingAddress {\n\t\t\t...FragmentBillingAddressFields\n\t\t}\n\t\tdeliveries {\n\t\t\t...FragmentDeliveriesFields\n\t\t}\n\t\tdeliveryMethods {\n\t\t\t...FragmentDeliveryMethods\n\t\t}\n\t\tgiftVoucher {\n\t\t\t...FragmentGiftVoucherFields\n\t\t}\n\t\tselectedDeliveryMethod {\n\t\t\t...FragmentSelectedDeliveryMethod\n\t\t}\n\t\tpromotions {\n\t\t\t...FragmentPromotionsFields\n\t\t}\n\t\tsummary {\n\t\t\t...FragmentSummaryFields\n\t\t}\n\t\tcommunicationMessages {\n\t\t\tid\n\t\t}\n\t\tcountryFeatures {\n\t\t\t...FragmentCountryFeatures\n\t\t}\n\t}\n'
): typeof import('./graphql').FragmentDraftPurchaseFieldsFragmentDoc
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\t\t\n\t\tmutation UpdateDeliveryAddress(\n\t\t\t$updateDeliveryAddressInput: UpdateDeliveryAddressInput!\n\t\t) {\n\t\t\tupdateDeliveryAddress(\n\t\t\t\tupdateDeliveryAddressInput: $updateDeliveryAddressInput\n\t\t\t) {\n\t\t\t\t...FragmentDraftPurchaseFields\n\t\t\t}\n\t\t}\n\t'
): typeof import('./graphql').UpdateDeliveryAddressDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\t\t\n\t\tmutation CreateDeliveryAddress(\n\t\t\t$createDeliveryAddressInput: CreateDeliveryAddressInput!\n\t\t) {\n\t\t\tcreateDeliveryAddress(\n\t\t\t\tcreateDeliveryAddressInput: $createDeliveryAddressInput\n\t\t\t) {\n\t\t\t\t...FragmentDraftPurchaseFields\n\t\t\t}\n\t\t}\n\t'
): typeof import('./graphql').CreateDeliveryAddressDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\t\t\n\t\tmutation RemoveCheckoutItem($removeItemInput: RemoveItemInput!) {\n\t\t\tremoveCheckoutItem(removeItemInput: $removeItemInput) {\n\t\t\t\t...FragmentDraftPurchaseFields\n\t\t\t}\n\t\t}\n\t'
): typeof import('./graphql').RemoveCheckoutItemDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\t\t\n\t\tmutation ApplyPromotionalCode(\n\t\t\t$promotionalCodeInput: PromotionalCodeInput!\n\t\t) {\n\t\t\tapplyPromotionalCode(promotionalCodeInput: $promotionalCodeInput) {\n\t\t\t\t...FragmentDraftPurchaseFields\n\t\t\t}\n\t\t}\n\t'
): typeof import('./graphql').ApplyPromotionalCodeDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\t\t\n\t\tmutation CancelPromotionalCode(\n\t\t\t$promotionalCodeInput: PromotionalCodeInput!\n\t\t) {\n\t\t\tcancelPromotionalCode(promotionalCodeInput: $promotionalCodeInput) {\n\t\t\t\t...FragmentDraftPurchaseFields\n\t\t\t}\n\t\t}\n\t'
): typeof import('./graphql').CancelPromotionalCodeDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\t\t\n\t\tmutation ApplyGiftVoucher($giftVoucherInput: GiftVoucherInput!) {\n\t\t\tapplyGiftVoucher(giftVoucherInput: $giftVoucherInput) {\n\t\t\t\t...FragmentDraftPurchaseFields\n\t\t\t}\n\t\t}\n\t'
): typeof import('./graphql').ApplyGiftVoucherDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\t\t\n\t\tmutation CancelGiftVoucher($giftVoucherInput: GiftVoucherInput!) {\n\t\t\tcancelGiftVoucher(giftVoucherInput: $giftVoucherInput) {\n\t\t\t\t...FragmentDraftPurchaseFields\n\t\t\t}\n\t\t}\n\t'
): typeof import('./graphql').CancelGiftVoucherDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\t\t\n\t\tmutation MergeDraftPurchase(\n\t\t\t$mergeDraftPurchaseInput: MergeDraftPurchaseInput!\n\t\t) {\n\t\t\tmergeDraftPurchase(mergeDraftPurchaseInput: $mergeDraftPurchaseInput) {\n\t\t\t\t...FragmentDraftPurchaseFields\n\t\t\t}\n\t\t}\n\t'
): typeof import('./graphql').MergeDraftPurchaseDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\t\t\n\t\tmutation SelectDeliveryMethod(\n\t\t\t$selectDeliveryMethodInput: SelectDeliveryMethodInput!\n\t\t) {\n\t\t\tselectDeliveryMethod(\n\t\t\t\tselectDeliveryMethodInput: $selectDeliveryMethodInput\n\t\t\t) {\n\t\t\t\t...FragmentDraftPurchaseFields\n\t\t\t}\n\t\t}\n\t'
): typeof import('./graphql').SelectDeliveryMethodDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\t\t\n\t\tmutation SelectedDraftPurchaseDeliveryMethod(\n\t\t\t$selectedDeliveryMethodInput: SelectedDeliveryMethodInput!\n\t\t) {\n\t\t\tselectedDraftPurchaseDeliveryMethod(\n\t\t\t\tselectedDeliveryMethodInput: $selectedDeliveryMethodInput\n\t\t\t) {\n\t\t\t\t...FragmentDraftPurchaseFields\n\t\t\t}\n\t\t}\n\t'
): typeof import('./graphql').SelectedDraftPurchaseDeliveryMethodDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\t\t\n\t\tmutation RefreshDeliveryMethods(\n\t\t\t$refreshDeliveryMethodsInput: RefreshDeliveryMethodsInput!\n\t\t) {\n\t\t\trefreshDeliveryMethods(\n\t\t\t\trefreshDeliveryMethodsInput: $refreshDeliveryMethodsInput\n\t\t\t) {\n\t\t\t\t...FragmentDraftPurchaseFields\n\t\t\t}\n\t\t}\n\t'
): typeof import('./graphql').RefreshDeliveryMethodsDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\t\tmutation InitializeCheckout(\n\t\t\t$initializeCheckoutInput: InitializeCheckoutInput!\n\t\t) {\n\t\t\tinitializeCheckout(initializeCheckoutInput: $initializeCheckoutInput) {\n\t\t\t\t__typename\n\t\t\t}\n\t\t}\n\t'
): typeof import('./graphql').InitializeCheckoutDocument
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\t\t\n\t\tquery GetDraftPurchase($getDraftPurchaseInput: GetDraftPurchaseInput!) {\n\t\t\tgetDraftPurchase(getDraftPurchaseInput: $getDraftPurchaseInput) {\n\t\t\t\t...FragmentDraftPurchaseFields\n\t\t\t}\n\t\t}\n\t'
): typeof import('./graphql').GetDraftPurchaseDocument

export function graphql(source: string) {
	return (documents as any)[source] ?? {}
}
